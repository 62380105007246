//判断是否为空
export const isNullOrEmpty = (param) => {
    return (
        param === undefined ||
        param === null ||
        (typeof param === 'string' && param.trim() === '') ||
        (Array.isArray(param) && param.length === 0) ||
        (typeof param === 'object' && Object.keys(param).length === 0) ||
        (typeof param !== 'boolean' && param === false) // 添加对布尔类型的处理
        // 可根据需要扩展其他情况的检查条件
    );
};

//时间戳转日期时间
export const TimestampToDateTime = (timestamp) => {
    var date = new Date(timestamp);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? '0' + m : m;
    var d = date.getDate();
    d = d < 10 ? '0' + d : d;
    var h = date.getHours();
    h = h < 10 ? '0' + h : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? '0' + minute : minute;
    second = second < 10 ? '0' + second : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
};
//获取当前向前推几年的时间
export const getSomeYearsBefor = (years) => {
    let resultDate, year, month, date, hms;
    let currDate = new Date();
    year = currDate.getFullYear();
    month = currDate.getMonth() + 1;
    date = currDate.getDate();
    hms =
        currDate.getHours() +
        ':' +
        currDate.getMinutes() +
        ':' +
        (currDate.getSeconds() < 10 ?
            '0' + currDate.getSeconds() :
            currDate.getSeconds());
    switch (year) {
        case 1:
        case 2:
        default:
            year -= years;
            break;
    }
    month = month < 10 ? '0' + month : month;
    resultDate = year + '-' + month + '-' + date + ' ' + hms;
    return resultDate;
};
export const getUrlKey = (name) => {
    //获取url中的参数
    return (
        decodeURIComponent(
            (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
                location.href
            ) || [, ''])[1].replace(/\+/g, '%20')
        ) || null
    );
};