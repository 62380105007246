const indexState = {
	uid: 'initialUid', // 初始的 uid
	clickuid: 'clickuid', // 点击的 uid
	leftNav: 'lists', //左侧列表
	quaryParams: 'object', //列表查询使用的参数
	userName: 'defaultname', //用户名称
	userToken: 'defaulttoken', //用户token
	specialSubjectNavUid: '',
	homeNavList: 'defaultList', //导航列表
};

export default indexState;
