<template>
	<div>
		<div>
			<!-- <button class="drawer-button" @click.stop="toggleDrawer">☰</button> -->
			<div class="drawer-content">
				<div class="cdcss">
					<div class="paddingcss">
						<!-- <div class="navMoblie"><span>栏目导航</span></div> -->
						<ul class="one_menu">
							<li>
								<div>
									<ul class="two_menu">
										<li
											v-for="(item, index) in tableData"
											:key="index"
										>
											<a
												@click="gotoLink(item.url)"
												href="javascript:void(0)"
												>{{ item.text }}</a
											>
										</li>
									</ul>
									<div class="clear"></div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Popup, Cell, CellGroup, Collapse, CollapseItem, Divider } from 'vant';
export default {
	name: 'homeNav',
	components: {
		Popup,
		Cell,
		CellGroup,
		Collapse,
		CollapseItem,
		Divider,
	},
	mixins: [],
	props: {},
	data() {
		return {
			activeName: this.$route.name,
			leftNavList: [],
			isMoblie: false,
			tableData: [
				{
					text: '综合版',
					url: 'http://www.zssgdsb-85176920tsgjnz.com',
				},
				{
					text: '教育版',
					url: 'https://edu.zssgdsb-85176920tsgjnz.com/',
				},
				{
					text: '党政版',
					url: 'http://government.zssgdsb-85176920tsgjnz.com/',
				},
			],
		};
	},
	computed: {},
	watch: {
		MobileVerification: {
			handler(to) {
				// console.log(to);
				this.isMoblie = this.MobileVerification;
				// this.isMobileVt();
				// this.isMobileValue();
			},
			immediate: true,
		},
	},
	mounted() {
		this.activeName = this.$route.name;
		// this.getNavs();
	},
	methods: {
		getNavs() {
			this.tableData = [];
			// const data = getNav({ version: 'A2' });
			// getNav({ version: 'A2' }).then((res) => {
			// 	this.leftNavList = res.data.data;
			// 	// localStorage.removeItem('leftNavList');
			// 	// localStorage.setItem(
			// 	// 	'leftNavList',
			// 	// 	JSON.stringify(res.data.data)
			// 	// );
			// 	for (let index = 0; index < res.data.data.length; index++) {
			// 		const element = res.data.data[index];
			// 		// console.log(element);
			// 		if (element.uid == 'A201') {
			// 			this.tableData = element.children;
			// 		}
			// 	}
			// 	this.tableData.unshift({
			// 		isnav: true,
			// 		text: '首页',
			// 		uid: '000',
			// 	});
			// });
		},
		gotoLink(url) {
			window.open(url);
		},
		//打开抽屉
		// toggleDrawer() {
		// 	this.isDrawerOpen = !this.isDrawerOpen;
		// },
	},
};
</script>
<style lang="less" scoped>
@media screen and (max-width: 769px) {
	.nav {
		width: 0 !important;
	}
}
.nav {
	width: 100%;
	min-width: 1200px;
	height: 45px;
	display: flex;
	line-height: 45px;
	background: #015293 url('../../assets/homeImage/navbj.jpg') repeat-x;
}
.nobackground {
}
.homeNavs {
	width: 1200px;
	max-width: 1200px;
	margin: 0 auto;
}
.nav .homeNavs .ul1 li {
	float: left;
}
.nav .homeNavs .ul1 li a {
	color: #fff;
	padding-right: 57px;
	font-size: 17px;
}
.nav .homeNavs .ul2 li {
	float: left;
}
.nav .homeNavs .ul2 li a {
	color: #fff;
	padding-right: 26px;
	font-size: 14px;
}
.nav .homeNavs li a:hover,
.nav .homeNavs li a.on {
	color: #ffe400;
}
</style>
<!-- 手机端样式 -->
<style scoped lang="less">
.drawer-button {
	position: fixed;
	top: 20px;
	left: 20px;
	background: transparent;
	border: none;
	cursor: pointer;
	font-size: 24px;
	z-index: 100; /* 更高的层级 */
}
.cdcss {
	// margin: 10px 0;
	background: #fff;
	position: relative;
	// .paddingcss {
	// 	padding: 0 10px;
	// }
	.navMoblie {
		border-bottom: 1px solid #ececec;
		position: relative;
		padding: 10px 10px 0 10px;
		span {
			font-size: 18px;
			color: #1a1a1a;
			height: 35px;
			line-height: 35px;
			position: relative;
			display: block;
		}
	}
	.all_menu {
		width: 100%;
		font-size: 16px;
		white-space: nowrap;
		overflow: auto;
		padding: 10px 0;
		a {
			color: #474747;
			padding-left: 15px;
			padding-right: 15px;
			border-right: 1px solid #eaeaea;
			text-decoration: none;
		}
		a:nth-child(1) {
			padding-left: 0px;
		}
		a:link,
		a:visited,
		a:active {
			color: #363636;
			text-decoration: none;
			cursor: pointer;
		}
	}
	.one_menu {
		padding: 10px;
		.acss {
			font-size: 20px;
			color: #474747;
			margin: 15px 0;
			display: block;
			background: url(../../assets/special/conlefticon.jpg) no-repeat left
				3px;
			height: 15px;
			padding-left: 16px;
		}
		.two_menu {
			a {
				font-size: 14px;
				color: #474747;
				display: block;
				float: left;
				padding: 6px 8px;
				border: 1px solid #f1f1f1;
				border-radius: 20px;
				margin: 5px;
			}
		}

		a:link,
		a:visited,
		a:active {
			color: #363636;
			text-decoration: none;
			cursor: pointer;
		}
	}
}
</style>
