//eventbus消息中间件-子组件向父组件传参
import Vue from 'vue';
const eventBus = new Vue();
export default eventBus;
/**
 * 
1utils工具类 命名bus.js 最好放在components或者utils目录下
  import Vue from "vue";
  const bus = new Vue();
  export default bus;
2加入到mian方法中（我用的是vue2）
  import eventBus from '@/utils/eventBus';
  Vue.prototype.$eventBus = eventBus;
3使用方法
//发消息
  let _this = this;
  _this.$bus.$emit("areaChangs", [参数1, 数据2]);
  //收消息
  let _this = this;
    this.$bus.$on("areaChangs", function(data) {
    //这个是匿名函数哦，不饿能直接用this
    _this.data= data;
    });
 * 
 */
