<template>
  <!-- 
    height : 传入header整体高度数值的字符串,不需要添加单位    如:height="113"
    setHeaderBackgroundImg && headerContentBackgroundImg : 状态1=>字符串none:不添加header层级的背景图;状态2=>"xxxxx.png"  如:headerBackgroundImg='HeaderBackgroundImg.png'
    versionName : 项目名称   如:versionName="[教育版]"
    versionNameVerticalAlign : 版本名称相对logo的垂直高度   如:versionNameVerticalAlign="16"
    versionNameShow : 版本名称显示=>1为显示,其他为不显示   如:versionNameShow="1"
    styleApi : logo模块(包括版本名称)的padding-top || padding-bottom || padding-left 如:styleApi="padding-top"   如:styleApi="padding-top"
    logoMargin: logo模块(包括版本名称)的margin-top || margin-bottom || margin-left的具体值   如:logoMargin="16"
    versionNameColor:字体颜色 选填
    contentWidth:宽度
    versionNameFontSize:版本名称字体大小
   -->
  <!-- 
                  <TopBanner
                    contentWidth="1111"
                    height="113"
                    versionName="[教育版]"
                    versionNameVerticalAlign="16"
                    versionNameShow="1"
                    versionNameColor="#ce342d"
                    versionNameFontSize="24"
                    styleApi="padding-top"
                    logoMargin="16"
                    headerBackgroundImg="HeaderBackgroundImg.png"
                    headerContentBackgroundImg="HeaderBackgroundImg.png"
                  ></TopBanner>

                  import TopBanner from "@/components/TopBanner/index.vue";

                  components: { TopBanner },
    -->
  <div
    class="header"
    :style="{
      height: height + 'px',
      'background-image':
        setHeaderBackgroundImg == 'none'
          ? 'none'
          : 'url(' + setHeaderBackgroundImg + ')',
    }"
  >
    <div
      class="header-content"
      ref="headerContent"
      :style="{
        'background-image':
          setHeaderContentBackgroundImg == 'none'
            ? 'none'
            : 'url(' + setHeaderContentBackgroundImg + ')',
        width: contentWidth + 'px',
      }"
    >
      <div
        class="header-logo"
        :style="styleApi + ':' + setLogoMargin + 'px;height:' + height + 'px'"
        ref="headerLogo"
      >
        <img src="./images/TopBannerLogo.png" alt="" />
        <a
          class="version-name"
          href=""
          :style="
            'vertical-align: ' +
            versionNameVerticalAlign +
            'px;color:' +
            versionNameColor +
            ';font-size:' +
            versionNameFontSize +
            'px;'
          "
          v-if="versionNameShow == 1"
          >{{ this.versionName }}</a
        >
      </div>
      <slot name="topBannerContent"></slot>
    </div>
  </div>
</template>

<script>
import "@/assets/css/base.css";
export default {
  name: "TopBanner",
  props: {
    // 整体headerBanner容器高度
    height: String,
    // 版本名称
    versionName: String,
    // 版本名称垂直位置
    versionNameVerticalAlign: String,
    // 版本名称显示：1为显示
    versionNameShow: String,
    // 版本名称颜色
    versionNameColor: String,
    // 版本名称字体大小
    versionNameFontSize: String,
    // padding-top || padding-bottom || padding-left
    styleApi: String,
    // logo高度
    logoMargin: String,
    // header背景图
    headerBackgroundImg: String,
    // 主体内容背景图
    headerContentBackgroundImg: String,
    // content Width
    contentWidth: String,
  },
  data() {
    return {
      setLogoMargin: "",
      // header背景图
      setHeaderBackgroundImg: "",
      // 主体内容背景图
      setHeaderContentBackgroundImg: "",
    };
  },
  mounted() {
    // logo：margin-top DATA
    this.setLogoMargin =
      this.logoMargin == "0"
        ? (this.height - this.$refs.headerLogo.offsetHeight) / 2
        : this.logoMargin;
    // header背景图
    if (this.headerBackgroundImg != "none") {
      this.setHeaderBackgroundImg = require("./images/" +
        this.headerBackgroundImg);
    } else {
      this.setHeaderBackgroundImg = "none";
    }
    // 主体内容背景图
    if (this.headerContentBackgroundImg != "none") {
      this.setHeaderContentBackgroundImg = require("./images/" +
        this.headerContentBackgroundImg);
    } else {
      this.setHeaderContentBackgroundImg = "none";
    }
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;

  // background-image: url(./images/HeaderBackgroundImg.png);
  .header-content {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    background-size: cover;
    // padding: 22px 0;
    .header-logo {
      display: inline-block;
      font-family: "微软雅黑";
      .version-name {
        font-size: 22px;
        font-weight: bold;
        color: rgb(39, 131, 210);
        margin-left: 16px;
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .header-content,.header-content {
    width: 100% !important;
   
  }
  .header{
    height: 161px !important;
  }
  .header-logo{
    padding-top: 48px !important
  }
}
</style>