<template>
	<div class="footer">
		<div class="home">
			<div class="left_f fl">
				<div v-if="isMoblie" class="menu1">
					<div>
						<a
							href="http://company.zssgdsb-85176920tsgjnz.com/about.html"
							target="_blank"
							>关于我们</a
						>|
						<a
							href="http://company.zssgdsb-85176920tsgjnz.com/cpyc.html#/recruit"
							target="_blank"
							>诚聘英才</a
						>|
						<a
							href="http://misc.zssgdsb-85176920tsgjnz.com/us/wangzsm.aspx"
							target="_blank"
							>网站声明</a
						>|
						<a
							href="http://company.zssgdsb-85176920tsgjnz.com/lxwm.html#/contact"
							target="_blank"
							>联系我们</a
						>
					</div>
					<div style="margin-top: 10px">
						<!-- <a
                href="javascript:void(0)"
                onclick='SetHome(this,"http://www.zssgdsb-85176920tsgjnz.com");'
                >设为首页</a
              >| -->
						<a
							href="http://misc.zssgdsb-85176920tsgjnz.com/DRCNet.Users.Web/User/UserSuggestAdd1.aspx"
							target="_blank"
							>意见和建议</a
						>|
						<a href="mailto:chiefeditor@zssgdsb-85176920tsgjnz.com">总监信箱</a>
					</div>
				</div>
				<div v-if="!isMoblie" class="menu1">
					<a
						href="http://company.zssgdsb-85176920tsgjnz.com/about.html"
						target="_blank"
						>关于我们</a
					>|
					<a
						href="http://company.zssgdsb-85176920tsgjnz.com/cpyc.html#/recruit"
						target="_blank"
						>诚聘英才</a
					>|
					<a
						href="http://misc.zssgdsb-85176920tsgjnz.com/us/wangzsm.aspx"
						target="_blank"
						>网站声明</a
					>|
					<a
						href="http://company.zssgdsb-85176920tsgjnz.com/lxwm.html#/contact"
						target="_blank"
						>联系我们</a
					>|
					<!-- <a
              href="javascript:void(0)"
              onclick='SetHome(this,"http://www.zssgdsb-85176920tsgjnz.com");'
              >设为首页</a
            >| -->
					<a
						href="http://misc.zssgdsb-85176920tsgjnz.com/DRCNet.Users.Web/User/UserSuggestAdd1.aspx"
						target="_blank"
						>意见和建议</a
					>|
					<a href="mailto:chiefeditor@zssgdsb-85176920tsgjnz.com">总监信箱</a>
				</div>

				<div class="tel">
					<img
						src="http://www.zssgdsb-85176920tsgjnz.com/images/tel.png"
						class="fl"
					/>
					<div class="hm fl">
						<div class="hmd">
							<span>热线电话：</span>
							<p>400-7070-678</p>
						</div>
					</div>
					<div class="clear"></div>
				</div>
				<div class="footer_bottom">
					Copyright©1998-{{ newYear }} DRCnet. All Rights
					Reserved&nbsp;版权所有 国研网
				</div>
			</div>
			<div class="right_f_wx fr" v-if="!isMoblie">
				<ul class="ewm">
					<li>
						<img
							src="https://www.zssgdsb-85176920tsgjnz.com/images/weixin.jpg"
							class="ewm1"
						/>
						<p>服务号</p>
					</li>
					<li>
						<img
							src="https://www.zssgdsb-85176920tsgjnz.com/images/jjzh.jpg"
							class="ewm1"
						/>
						<p>订阅号</p>
					</li>
					<!-- <li>
              <img
                src="https://www.zssgdsb-85176920tsgjnz.com/images/drcnet-app-andriod-qrcode.jpg"
                class="ewm1"
              />
              <p>安卓版</p>
            </li> -->
					<!-- <li>
              <img
                src="https://www.zssgdsb-85176920tsgjnz.com/images/drcnet-app-ios-qrcode.jpg"
                class="ewm1"
              />
              <p>IOS版</p>
            </li> -->
				</ul>
			</div>
			<div class="clear"></div>
		</div>
		<div class="clear"></div>
		<div v-if="isMoblie" class="bottom-f">
			<div class="home">
				<span>
					<a
						class="ipc"
						href="https://beian.miit.gov.cn/"
						target="_blank"
						>增值电信业务经营许可证
						京ICP证030059号&nbsp;&nbsp;&nbsp;&nbsp;</a
					></span
				>
				<span>
					<a
						class="ipc"
						href="https://www.zssgdsb-85176920tsgjnz.com/announcement/zizhiimg2"
						target="_blank"
						>增值电信业务经营许可证
						京B2-20070136&nbsp;&nbsp;&nbsp;&nbsp;</a
					></span
				>

				<span>
					<a
						class="ipc"
						href="https://www.zssgdsb-85176920tsgjnz.com/announcement/zizhiimg4"
						target="_blank"
						>网络出版服务许可证：网出证京字第420号</a
					></span
				>

				<span>
					<a
						class="ipc"
						href="https://www.zssgdsb-85176920tsgjnz.com/announcement/zizhiimg3"
						target="_blank"
						>出版物经营许可证：新出发京零字第东230083号&nbsp;&nbsp;&nbsp;&nbsp;</a
					></span
				>

				<a
					href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102002958"
					target="_blank"
				>
					<a
						href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102002958"
						target="_blank"
					>
						<span>京公网安备 11010102002958号</span>
						<img
							style="vertical-align: middle; padding-left: 5px"
							src="../../assets/footerImage/beians.png"
						/>
					</a>
				</a>
				<span style="padding-left: 10px">建议浏览器：火狐、谷歌</span>
			</div>
		</div>
		<div v-if="!isMoblie" class="bottom-f">
			<div class="home">
				<ul class="bq">
					<li class="tips">
						<span class="fl">
							<a
								class="ipc"
								href="https://beian.miit.gov.cn/"
								target="_blank"
								>增值电信业务经营许可证
								京ICP证030059号&nbsp;&nbsp;&nbsp;&nbsp;</a
							></span
						>

						<div class="clear"></div>
					</li>

					<li class="tips">
						<span class="fl">
							<a
								class="ipc"
								href="https://www.zssgdsb-85176920tsgjnz.com/announcement/zizhiimg4"
								target="_blank"
								>网络出版服务许可证：网出证京字第420号&nbsp;&nbsp;&nbsp;&nbsp;</a
							></span
						>

						<div class="clear"></div>
					</li>
					<li class="tips">
						<span class="fl">
							<a
								class="ipc"
								href="https://www.zssgdsb-85176920tsgjnz.com/announcement/zizhiimg3"
								target="_blank"
								>出版物经营许可证：新出发京零字第东230083号&nbsp;&nbsp;&nbsp;&nbsp;</a
							></span
						>

						<div class="clear"></div>
					</li>
					<li class="tips">
						<a
							href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102002958"
							target="_blank"
							class="fl"
						>
							<a
								href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102002958"
								target="_blank"
								class="fl"
							>
								<span class="fl"
									>京公网安备 11010102002958号</span
								>
								<img
									src="../../assets/footerImage/beians.png"
								/>
							</a>
						</a>
						<div class="clear"></div>
					</li>
					<li class="tips" style="float: right">
						<span>建议浏览器：火狐、谷歌</span>
						<div class="clear"></div>
					</li>
				</ul>
				<ul class="bq">
					<li class="tips">
						<span class="fl">
							<a
								class="ipc"
								href="https://www.zssgdsb-85176920tsgjnz.com/announcement/zizhiimg2"
								target="_blank"
								>增值电信业务经营许可证
								京B2-20070136&nbsp;&nbsp;&nbsp;&nbsp;</a
							></span
						>

						<div class="clear"></div>
					</li>
				</ul>
				<div class="clear"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Footer',
	data() {
		return {
			newYear: new Date().getFullYear(),
			isMoblie: false,
		};
	},
	watch: {
		MobileVerification: {
			handler(to) {
				// console.log(to);
				this.isMoblie = this.MobileVerification;
				// this.isMobileVt();
				// this.isMobileValue();
			},
			immediate: true,
		},
	},
	mounted() {},
};
</script>

<style lang="less" scoped>
.footer {
	color: #fff;
	background: #2b303a;
	height: 266px;
	padding-top: 0;
	margin-top: 0;
}
.menu1 {
	color: #525e6d;
	font-size: 15px;
	a {
		padding: 0 10px;
		font-size: 15px;
		color: #fff;
	}
	a:nth-child(1) {
		padding-left: 0;
	}
}
.right_f {
	text-align: center;
	width: 122px;
}
.right_f img {
	width: 110px;
	margin: 0 auto;
}
.right_f p {
	font-size: 12px;
	color: #797b80;
	padding-top: 10px;
}
.bottom-f {
	color: #797b80;
	background: #20242c;
	// height: 45px;
	// line-height: 45px;
	margin-top: 30px;
	.home {
		display: flex;
		flex-direction: column;
		padding: 10px 0;
	}
	a {
		color: #797b80;
		display: inline-block;
	}
	.bq {
		line-height: 36px;
	}
	.bq li {
		float: left;
		span {
			color: #797b80;
			font-size: 12px;
		}
		img {
			padding-left: 7px;
			padding-top: 5px;
			padding-right: 0px;
		}
	}
}
.tel {
	width: 400px;
	padding-top: 50px;
	img {
		padding-right: 15px;
	}
}
.tel .hm p,
.hm span,
.footer_bottom {
	font-size: 14px;
	padding-top: 20px;
	color: #797b80;
}
.tel .hm p {
	font-size: 22px;
}
.hmd {
	span {
		font-size: 16px;
	}
	p {
		padding-top: 10px !important;
		font-size: 22px;
	}
}
.dtgif {
	position: relative;
	left: -50px;
	display: none;
}
.ewm li {
	float: left;
}
.ewm li img {
	width: 100px;
	height: 100px;
	margin: 0 5px;
}
.menu a {
	font-size: 15px;
}
.footer {
	padding-top: 35px;
}
.right_f_wx {
	min-width: 220px;
	margin-top: 20px;
	text-align: center;
}
.right_f_wx p {
	font-size: 13px;
	color: #797b80;
	padding-top: 10px;
}
</style>
<style lang="less" scoped>
@media screen and (max-width: 769px) {
	.footer {
		max-width: 100% !important;
		margin: 0 auto;
		.home {
			width: 100% !important;
			align-items: center;
		}
		.menu1 {
			width: 100% !important;
			text-align: center;
		}
		.tel {
			padding-top: 30px !important;
			width: 100% !important;
			text-align: center;
			display: flex;
			margin-left: -10px !important;
			justify-content: center;
		}
		.footer_bottom {
			text-align: center;
			padding: 10px 20px 0 20px !important;
		}
		.bottom-f {
			height: 70% !important;
			font-size: 12px;
			padding: 0 20px;
			a {
				display: flex !important;
				line-height: 25px;
			}
			img {
				max-width: 23px;
			}
		}
	}
}
</style>
