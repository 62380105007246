<!-- 引入
  import Search from "@/components/Search";
  components: {Search,},
 -->
<!-- 使用
    <Search
      searchPosition="0"    // 字符串长度=1，搜索框位置，0：左；1：右;2：中,其他：具体的值   父级添加position-relative**非必传**
      selectType="2,3,4"    // 字符串长度=不限，字符串以逗号为分割， 搜索类型，以逗号分隔，1：全文，2：作者，3：关键词，4：来源**必传长度不限**
      keyWordsInputTab="请输入关键词"     // 字符串长度=不限，关键词输入提示**必传长度不限**
      searchUrl="1,7023"    // 字符串以逗号为分割，字符串长度=2，搜索的url 0：普通搜索 rootlm为商品编号；1为专栏搜索  chnid为栏目编号 **并传入响应id****必传**
      searchBoxWidth="80,1" // 字符串以逗号为分割，字符串长度=2，searchWidthStyle的宽度，第一位为宽度值，第二位0：单位为px；1：单位为%**需要设置值占位**
      simpleSearchIcon="https://images.drcnet-sod.com/Images/Common/search_a.jpg" 普通搜索icon 选填
      searchInputWidth="100,1"  // 字符串以逗号为分割，字符串长度=2，input的宽度，第一位为宽度值，第二位0：单位为px；1：单位为%**需要设置值占位**
      inputAndSelectWrapWidth="63,1"  // 字符串以逗号为分割，字符串长度=2，input和多选框共同容器的宽度，第一位为宽度值，第二位0：单位为px；1：单位为%**需要设置值占位**
      simpleSearchButton="30,20,#2783d2,8,12"  // 普通搜索按钮 字符串以逗号为分割，字符串长度=5，从前到后依次为：高度=》宽度=》背景色=》padding左右=》字体大小**需要设置值占位**
      heighSearchButton="30,20,#27a0d2,8,12"   // 高级检索按钮 字符串以逗号为分割，字符串长度=5，从前到后依次为：高度=》宽度=》背景色=》padding左右=》字体大小**需要设置值占位**
      heighSearchUrl="0,25566"  //  搜索页类型与栏目
    ></Search>
 -->
<!--   如果位置发生问题：请设置#app样式
  #app {
    width: 100%;
    height: 100px;
    position: relative;
  }
 -->
 <!--  插槽
      add =>自己配置的class
      <template slot="topBannerContent">
        <Search
          class="add"
          searchPosition="0"
          selectType="2,3,4"
          keyWordsInputTab="请输入关键词"
          searchUrl="1,7023"
          searchBoxWidth="80,1"
          searchInputWidth="100,1"
          inputAndSelectWrapWidth="63,1"
          simpleSearchButton="30,20,#2783d2,8,12"
          heighSearchButton="30,20,#27a0d2,8,12"
        />
      </template>
      css 
      .add {
        position: absolute;
        right: 0;
        top: 30px;
      }
  -->

<template>
  <div
    class="searchbox"
    ref="searchWidthStyle"
    :style="{
      width:
        searchBoxWidthValue[1] == '0'
          ? searchBoxWidthValue[0] + 'px'
          : searchBoxWidthValue[0] + '%',
    }"
  >
    <div
      class="search"
      :style="{
        width:
          inputAndSelectWrapWidthValue[1] == '0'
            ? inputAndSelectWrapWidthValue[0] + 'px'
            : inputAndSelectWrapWidthValue[0] + '%',
      }"
    >
      <el-select
        v-model="value"
        ref="selectValue"
        @change="selectKeys"
        :placeholder="'搜索类型'"
        :style="{
          '--elHeight': `${simpleSearchButtonValue[0]}px`,
        }"
      >
        <el-option
          v-for="item in useSetSelectOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <div
        class="searchinput"
        :style="{
          float: 'left',
          width:
            searchInputWidthValue[1] == '0'
              ? searchInputWidthValue[0] + 'px'
              : `calc(${searchInputWidthValue[0]}%  - 76px)`,
          float: 'left',
        }"
      >
        <el-input
          v-model="keywords"
          :style="{
            '--elHeight': `${simpleSearchButtonValue[0]}px`,
            width:
              searchInputWidthValue[1] == '0'
                ? searchInputWidthValue[0] + 'px'
                : searchInputWidthValue[0] + '%',
            float: 'left',
          }"
          :placeholder="keyWordsInputTab == undefined ? '' : keyWordsInputTab"
        ></el-input>
      </div>
    </div>
    <!--  'padding-let':simpleSearchButtonValue[3],
        'padding-let':simpleSearchButtonValue[3], -->
    <div
      class="searchbtna"
      @click="searchKeys()"
      :style="{
        height: simpleSearchButtonValue[0] + 'px',
        // width: simpleSearchButtonValue[1]+'px',
        lineHeight: simpleSearchButtonValue[0] + 'px',
        background: simpleSearchButtonValue[2],
        padding: '0 ' + simpleSearchButtonValue[3] + 'px',
        'font-size': simpleSearchButtonValue[4] + 'px',
      }"
    >
      <img
        v-if="simpleSearchIcon"
        :src="simpleSearchIcon"
        alt=""
        :style="
          'display: inline-block; vertical-align: middle;margin-left: -' +
          simpleSearchButtonValue[3] +
          'px;'
        "
      />
      检索
    </div>
    <a
      :style="{
        height: heighSearchButtonValue[0] + 'px',
        // width: simpleSearchButtonValue[1]+'px',
        lineHeight: heighSearchButtonValue[0] + 'px',
        background: heighSearchButtonValue[2],
        padding: '0 ' + heighSearchButtonValue[3] + 'px',
        'font-size': heighSearchButtonValue[4] + 'px',
      }"
      @click="heighSearchKeys()"
      target="blank"
      class="searchbtnb"
      >高级检索</a
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      keywords: "",
      useSetSelectOptions: [],
      value: "",
      options: [
        {
          value: "0",
          label: "全文",
        },
        {
          value: "1",
          label: "标题",
        },
        {
          value: "2",
          label: "作者",
        },
        {
          value: "3",
          label: "关键词",
        },
        {
          value: "4",
          label: "来源",
        },
      ],
      searchPositionValue: this.searchPosition,
      searchWidth: "",
      simpleSearchButtonValue: [],
      heighSearchButtonValue: [],
      searchInputWidthValue: [],
      searchBoxWidthValue: [],
      inputAndSelectWrapWidthValue: [],
    };
  },
  props: {
    selectType: String, //字符串以逗号为分割， 搜索类型，以逗号分隔，1：全文，2：作者，3：关键词，4：来源
    keyWordsInputTab: String, //关键词输入提示
    searchUrl: String, // 搜索的url 0：普通搜索 rootlm为商品编号；1为专栏搜索  chnid为栏目编号
    searchPosition: String, // 字符串以逗号为分割，搜索框位置，0：左；1：右;2：中,其他：具体的值   父级添加position-relative
    searchInputWidth: String, //字符串以逗号为分割，搜索输入框宽度 两种常量=》第一位为宽度值，第二位0：单位为px；1：范围为%
    searchBoxWidth: String, // 同上
    inputAndSelectWrapWidth: String, // 同上
    simpleSearchIcon: String, //普通搜索icon 选填
    simpleSearchButton: String, // 字符串以逗号为分割，从前到后依次为：高度=》宽度=》背景色=》padding左右=》字体大小
    heighSearchButton: String, // 同上
    heighSearchUrl: String,
  },
  created() {
    this.setSelectType();
    this.value = "1";
  },
  mounted() {
    this.searchWidthStyle();
    this.searchPositionSet();
    this.searchButtonSetting();
  },
  methods: {
    setSelectType() {
      let toArray = this.selectType.split(",");
      let rootArray = this.options;
      for (let i = 0; i < rootArray.length; i++) {
        for (let j = 0; j < toArray.length; j++) {
          if (rootArray[i].value == toArray[j]) {
            this.useSetSelectOptions.push(rootArray[i]);
          }
        }
      }
    },
    selectKeys(val) {

      this.value = val;
    },
    searchKeys() {
      if (this.keywords.length === 0) {
        this.$message({
          message: "请输入查询信息",
          type: "warning",
        });
        return;
      }
      let urlArray = this.searchUrl.split(",");
      if (urlArray[0] == "0") {
        window.open(
          "http://s1.zssgdsb-85176920tsgjnz.com/search/page.aspx?fields=" +
            this.$refs.selectValue.selected.value +
            "^1^1^" +
            escape(this.keywords) +
            "&rootlm=" +
            urlArray[1],
          "Search"
        ); // 弹出一个新页面
        LogCollection.send(9);
      } else if (urlArray[0] == "1") {
        window.open(
          ("http://s1.zssgdsb-85176920tsgjnz.com/search/searchAC.aspx?fields=" +
            this.$refs.selectValue.selected.value +
            "^1^1^" +
            escape(this.keywords) +
            "&chnid=" +
            urlArray[1] +
            "&rootlm=" +
            c7023) |
            c7023,
          "Search"
        ); // 弹出一个新页面
        LogCollection.send(9);
      } else if (urlArray[0] == "2") {
        window.open("http://s1.zssgdsb-85176920tsgjnz.com/search/SearchAdvanced.aspx"); // 弹出一个新页面
        LogCollection.send(9);
      } else if (urlArray[0] == "3") {
        window.open(
          window.open(
            "http://s1.zssgdsb-85176920tsgjnz.com/search/searchAC.aspx?fields=" +
              this.$refs.selectValue.selected.value +
              "^1^1^" +
              escape(this.keywords) +
              "&chnid=" +
              urlArray[1]
          )
        ); // 弹出一个新页面
        LogCollection.send(9);
      }
      this.keywords = "";
      // this.value = 1;
    },
    searchWidthStyle() {
      this.searchWidth = this.$refs.searchWidthStyle.offsetWidth;
    },
    searchPositionSet() {
      if (this.searchPosition == "0") {
        this.$refs.searchWidthStyle.style.left = "0px";
      } else if (this.searchPosition == "1") {
        this.$refs.searchWidthStyle.style.right = "0px";
      } else if (this.searchPosition == "2") {
        this.$refs.searchWidthStyle.style.left =
          "calc((100% - " + this.searchWidth + "px) / 2)";
      }
    },
    searchButtonSetting() {
      this.simpleSearchButtonValue = this.simpleSearchButton.split(",");
      this.heighSearchButtonValue = this.heighSearchButton.split(",");
      this.searchBoxWidthValue = this.searchBoxWidth.split(",");
      this.searchInputWidthValue = this.searchInputWidth.split(",");
      this.inputAndSelectWrapWidthValue =
        this.inputAndSelectWrapWidth.split(",");
    },
    heighSearchKeys() {
      let urlArray = this.heighSearchUrl.split(",");
      if (urlArray[0] == "0") {
        window.open(
          "http://s1.zssgdsb-85176920tsgjnz.com/search/page.aspx?fields=" +
            this.$refs.selectValue.selected.value +
            "^1^1^" +
            escape(this.keywords) +
            "&rootlm=" +
            urlArray[1],
          "Search"
        ); // 弹出一个新页面
        LogCollection.send(9);
      } else if (urlArray[0] == "1") {
        window.open(
          "http://s1.zssgdsb-85176920tsgjnz.com/search/searchAC.aspx?fields=" +
            this.$refs.selectValue.selected.value +
            "^1^1^" +
            escape(this.keywords) +
            "&rootlm=" +
            urlArray[1] +
            "|" +
            urlArray[1],
          "Search"
        ); // 弹出一个新页面
        LogCollection.send(9);
      } else if (urlArray[0] == "2") {
        window.open(
          "http://s1.zssgdsb-85176920tsgjnz.com/search/SearchAdvanced.aspx?fields=" +
            this.$refs.selectValue.selected.value +
            "^1^1^" +
            escape(this.keywords) +
            "&rootlm=" +
            urlArray[1] +
            "|" +
            urlArray[1] 
        ); // 弹出一个新页面
        LogCollection.send(9);
      }
      this.keywords = "";
      // this.value = 1;
    },
  },
};
</script>

<style lang='less' scoped>
.position-left {
  left: 0;
}
.position-right {
  right: 0;
}
.searchbox {
  // float: center;
  position: absolute;
  white-space: nowrap;
  .search {
    float: left;
    box-sizing: border-box;
    .el-select {
      float: left;
      width: 75px;
    }
  }
  .searchbtna {
    // padding: 0 16px;
    background-color: #2783d2;
    border-right: 1px solid;
  }
  .searchbtnb {
    display: block;
    // padding: 0 16px;
    background-color: #27a0d2;
  }
  .searchbtna,
  .searchbtnb {
    text-align: center;
    // line-height: 36px;
    float: left;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
  }
  /deep/.el-input__inner {
    height: var(--elHeight);
    // line-height: 36px;
    color: #575757;
    border-radius: 0px;
    padding-right: 0px;
    padding: 0 8px;
  }
}
/deep/.el-input__icon {
  line-height: var(--elHeight);
}
@media screen and (max-width: 769px) {
  .searchbox {
    width: 100% !important;
    padding: 0 calc((50% - 129px) / 2);
    .search {
      width: calc(100% - 129px) !important;
      .searchinput {
        width: calc(100% - 75px) !important;
      }
    }
  }
  .el-input {
    width: 100% !important;
  }
}
</style>