import Layout from '../../layout/detail.vue';
const articleRouter = {
	path: '/',
	component: Layout,
	redirect: '/',
	name: 'drcnetArticle',
	meta: {
		title: '1',
		sort: 4,
	},
	children: [
		{
			path: '/',
			component: () =>
				import('@/pages/detailspage/views/articleDetail/index.vue'),
			name: 'detail',
		},
		{
			path: '/download',
			component: () =>
				import('@/pages/detailspage/views/articleDetail/download.vue'),
			name: 'detail',
		},
		{
			path: '/Vuepdf',
			component: () =>
				import('@/pages/detailspage/views/articleDetail/vuePdf.vue'),
			name: 'Vuepdf',
			props: true, // 将路径参数作为 props 传递给组件
		},
	],
};

export default articleRouter;
