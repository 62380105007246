<template>
	<div style="background-color: #f3f6fb">
		<topNavigation
			v-show="isPath != 'Vuepdf'"
			:addNavListLeft="[
				{
					name: '综合版',
					toLink: 'https://www.zssgdsb-85176920tsgjnz.com/',
					color: '#0065cc',
					fontWeight: 600,
				},
				{
					name: '教育版',
					toLink: 'https://edu.zssgdsb-85176920tsgjnz.com/',
					color: '#0065cc',
					fontWeight: 600,
				},
				{
					name: '党政版',
					toLink: 'http://government.zssgdsb-85176920tsgjnz.com/',
					color: '#0065cc',
					fontWeight: 600,
				},
				// {
				// 	name: '金融版',
				// 	toLink: 'http://finance.zssgdsb-85176920tsgjnz.com/',
				// 	color: '#0065cc',
				// 	fontWeight: 600,
				// },
			]"
			:deleteSort="[1, 2, 3]"
			:defalutSort="[4, 5, 6, 3]"
			:alginRight="true"
		>
			<template v-slot:nav-top-content-isMobile-bottom>
				<homeNavIsMoblie style="width: 310px"></homeNavIsMoblie>
			</template>
		</topNavigation>
		<appMain></appMain>
		<footers></footers>
		<BackTop></BackTop>
	</div>
</template>
<script>
import topNavigation from '@/components/topNavigation.vue';
import TopBanner from '@/components/TopBanner';
import appMain from './components/appMain.vue';
import homeNavIsMoblie from './components/homeNavIsMoblie.vue';
import footers from './components/footer.vue';
import Search from '@/components/Search';
import BackTop from './components/backTop';
export default {
	name: '',
	components: {
		topNavigation,
		TopBanner,
		footers,
		appMain,
		Search,
		homeNavIsMoblie,
		BackTop,
	},
	mixins: [],
	props: {},
	data() {
		return {
			assemblyList: [],
			isMoblie: false,
			isPath: '',
		};
	},
	computed: {},
	watch: {
		MobileVerification: {
			handler(to) {
				this.isMoblie = this.MobileVerification;
			},
			immediate: true,
		},
	},
	created() {
		this.isPath = this.$route.name;
	},
	mounted() {},
	methods: {},
	beforeDestroy() {},
};
</script>
<style lang="less" scoped>
.add {
	position: absolute;
	right: 0;
	top: 60px;
}

@media screen and (max-width: 769px) {
	.add {
		position: absolute;
		right: 0;
		top: 90px !important;
		bottom: 0px !important;
		display: flex;
	}
}
</style>
<style>
a {
	text-decoration: none !important;
}
</style>
