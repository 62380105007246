var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isMobile && _vm.isMobileDefault != false)?_c('div',[_c('Icon',{staticClass:"drawer-button",attrs:{"name":"list-switch","size":"30"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleDrawer.apply(null, arguments)}}}),_c('Popup',{style:({ height: '100%', minWidth: '310px' }),attrs:{"position":"right"},model:{value:(_vm.isDrawerOpen),callback:function ($$v) {_vm.isDrawerOpen=$$v},expression:"isDrawerOpen"}},[_vm._t("nav-top-content-isMobile"),(_vm.isMobileListDefault != false)?_c('div',{staticClass:"drawer-content"},[_c('div',{staticClass:"userInfos"},[(_vm.isLoginDefault != false)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowLogin),expression:"isShowLogin"}],staticClass:"userInfosLogin",on:{"click":_vm.isMobileLoginTo}},[_vm._v(" 登录 ")]):_vm._e(),(_vm.isLoginDefault != false)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowName),expression:"isShowName"}],staticClass:"userInfosInfo"},[_vm._v(" 欢迎您，"+_vm._s(_vm.userName)+" ")]):_vm._e(),(_vm.isLoginDefault != false)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowName),expression:"isShowName"}],staticClass:"userInfosOut",on:{"click":_vm.loginOut}},[_vm._v(" 退出 ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowLogin),expression:"isShowLogin"}],staticClass:"userInfosPass",on:{"click":function($event){return _vm.regLink(
									'http://misc.zssgdsb-85176920tsgjnz.com/DRCNet.Users.Web/User/UserPwdGet.aspx'
								)}}},[_vm._v(" 忘记密码 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowLogin),expression:"isShowLogin"}],staticClass:"userInfosReg",on:{"click":function($event){return _vm.regLink(
									'http://misc.zssgdsb-85176920tsgjnz.com/DRCNet.Users.Web/User/UserReg1.aspx'
								)}}},[_vm._v(" 注册 ")])])]):_vm._e(),_vm._t("nav-top-content-isMobile-bottom")],2)],1):_c('div',{staticClass:"toplogin",style:({ height: _vm.topHeight ? _vm.topHeight : '' })},[(!_vm.alginRight)?_c('div',{staticClass:"home clearfix",style:({ width: _vm.homeWidth ? _vm.homeWidth : '' })},[_c('ul',{staticClass:"nav-top fl clearfix"},[_vm._t("nav-top-content-l"),(_vm.isLoginDefault != false)?_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowLogin),expression:"isShowLogin"}],staticClass:"fl"},[_c('a',{staticClass:"nav_a",style:({ color: _vm.fontColor }),attrs:{"href":_vm.loginUrl},on:{"click":_vm.login}},[_vm._v("登录")])]):_vm._e(),(_vm.isLoginDefault != false)?_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowName),expression:"isShowName"}],staticClass:"fl"},[_c('span',{staticClass:"nav_a",staticStyle:{"color":"red","font-size":"16px"},attrs:{"href":"javascript:;"}},[_vm._v(_vm._s('欢迎您，' + _vm.userName))]),_c('a',{staticClass:"nav_a",attrs:{"href":"javascript:;"},on:{"click":_vm.loginOut}},[_vm._v("退出")])]):_vm._e(),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowLogin),expression:"isShowLogin"}],staticClass:"fl"},[_c('a',{style:({ color: _vm.fontColor }),attrs:{"href":"http://misc.zssgdsb-85176920tsgjnz.com/DRCNet.Users.Web/User/UserPwdGet.aspx"}},[_vm._v("忘记密码")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowLogin),expression:"isShowLogin"}],staticClass:"fl"},[_c('a',{style:({ color: _vm.fontColor }),attrs:{"href":"http://misc.zssgdsb-85176920tsgjnz.com/DRCNet.Users.Web/User/UserReg1.aspx"}},[_vm._v("注册")])]),_vm._l((_vm.navButtonListLeft),function(item,index){return _c('li',{key:index,staticClass:"fl"},[_c('a',{style:({
								color: item.color
									? item.color
									: _vm.fontColor
									? _vm.fontColor
									: '',
								fontSize: _vm.fontSize ? _vm.fontSize : '',
								fontFamily: _vm.fontFamily ? _vm.fontFamily : '',
								fontWeight: item.fontWeight
									? item.fontWeight
									: 500,
							}),attrs:{"href":item.toLink,"target":"_blank"}},[_vm._v(_vm._s(item.name))])])})],2),_c('ul',{staticClass:"nav-top fr clearfix"},[_vm._l((_vm.navButtonListRight),function(item,index){return _c('li',{key:index,staticClass:"fl"},[_c('a',{style:({
								color: item.color
									? item.color
									: _vm.fontColor
									? _vm.fontColor
									: '',
								fontSize: _vm.fontSize ? _vm.fontSize : '',
								fontFamily: _vm.fontFamily ? _vm.fontFamily : '',
							}),attrs:{"href":item.toLink,"target":"_blank"}},[_vm._v(_vm._s(item.name))])])}),(_vm.databaseDefalut != false)?_c('li',{staticClass:"fl"},[_c('el-dropdown',{attrs:{"placement":"top"}},[_c('span',{staticClass:"el-dropdown-link"},[_c('a',{style:({ color: _vm.fontColor })},[_vm._v("特色数据库")])]),_c('el-dropdown-menu',{staticClass:"dropdownTopPbulicMenu",attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.navDataBaseDropdown),function(item,index){return _c('el-dropdown-item',{key:index},[_c('a',{attrs:{"href":item.toLink,"target":"_blank "}},[_vm._v(_vm._s(item.name))])])}),1)],1)],1):_vm._e(),(_vm.useAddEndRightMenuList.length > 0)?_c('div',{staticStyle:{"display":"contents"}},_vm._l((_vm.useAddEndRightMenuList),function(item,index){return _c('li',{key:index,staticClass:"fl"},[_c('a',{style:({
									color: item.color
										? item.color
										: _vm.fontColor
										? _vm.fontColor
										: '',
									fontSize: _vm.fontSize ? _vm.fontSize : '',
									fontFamily: _vm.fontFamily ? _vm.fontFamily : '',
								}),attrs:{"href":item.toLink,"target":"_blank"}},[_vm._v(_vm._s(item.name))])])}),0):_vm._e(),_vm._t("nav-top-content-r")],2)]):_c('div',{staticClass:"home clearfix",style:({ width: _vm.homeWidth ? _vm.homeWidth : '' })},[_c('ul',{staticClass:"nav-top fr clearfix"},[_vm._t("nav-top-content-align-r"),(_vm.isLoginDefault != false)?_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowLogin),expression:"isShowLogin"}],staticClass:"fl"},[_c('a',{staticClass:"nav_a",style:({ color: _vm.fontColor }),attrs:{"href":_vm.loginUrl},on:{"click":_vm.login}},[_vm._v("登录")])]):_vm._e(),(_vm.isLoginDefault != false)?_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowName),expression:"isShowName"}],staticClass:"fl"},[_c('span',{staticClass:"nav_a",staticStyle:{"color":"red","font-size":"16px"},attrs:{"href":"javascript:;"}},[_vm._v(_vm._s('欢迎您，' + _vm.userName))]),_c('a',{staticClass:"nav_a",attrs:{"href":"javascript:;"},on:{"click":_vm.loginOut}},[_vm._v("退出")])]):_vm._e(),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowLogin),expression:"isShowLogin"}],staticClass:"fl"},[_c('a',{style:({ color: _vm.fontColor }),attrs:{"href":"http://misc.zssgdsb-85176920tsgjnz.com/DRCNet.Users.Web/User/UserPwdGet.aspx"}},[_vm._v("忘记密码")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowLogin),expression:"isShowLogin"}],staticClass:"fl"},[_c('a',{style:({ color: _vm.fontColor }),attrs:{"href":"http://misc.zssgdsb-85176920tsgjnz.com/DRCNet.Users.Web/User/UserReg1.aspx"}},[_vm._v("注册")])]),_vm._l((_vm.navButtonListLeft),function(item,index){return _c('li',{key:index,staticClass:"fl"},[_c('a',{style:({
								color: item.color
									? item.color
									: _vm.fontColor
									? _vm.fontColor
									: '',
								fontSize: _vm.fontSize ? _vm.fontSize : '',
								fontFamily: _vm.fontFamily ? _vm.fontFamily : '',
								fontWeight: item.fontWeight
									? item.fontWeight
									: 500,
							}),attrs:{"href":item.toLink,"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" "),(item.isNew)?_c('img',{attrs:{"src":require("../assets/imgs/public/listnews.gif"),"alt":""}}):_vm._e()])])})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }