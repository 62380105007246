var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#f3f6fb"}},[_c('topNavigation',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPath != 'Vuepdf'),expression:"isPath != 'Vuepdf'"}],attrs:{"addNavListLeft":[
			{
				name: '综合版',
				toLink: 'https://www.zssgdsb-85176920tsgjnz.com/',
				color: '#0065cc',
				fontWeight: 600,
			},
			{
				name: '教育版',
				toLink: 'https://edu.zssgdsb-85176920tsgjnz.com/',
				color: '#0065cc',
				fontWeight: 600,
			},
			{
				name: '党政版',
				toLink: 'http://government.zssgdsb-85176920tsgjnz.com/',
				color: '#0065cc',
				fontWeight: 600,
			},
			// {
			// 	name: '金融版',
			// 	toLink: 'http://finance.zssgdsb-85176920tsgjnz.com/',
			// 	color: '#0065cc',
			// 	fontWeight: 600,
			// },
		],"deleteSort":[1, 2, 3],"defalutSort":[4, 5, 6, 3],"alginRight":true},scopedSlots:_vm._u([{key:"nav-top-content-isMobile-bottom",fn:function(){return [_c('homeNavIsMoblie',{staticStyle:{"width":"310px"}})]},proxy:true}])}),_c('appMain'),_c('footers'),_c('BackTop')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }