var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header",style:({
    height: _vm.height + 'px',
    'background-image':
      _vm.setHeaderBackgroundImg == 'none'
        ? 'none'
        : 'url(' + _vm.setHeaderBackgroundImg + ')',
  })},[_c('div',{ref:"headerContent",staticClass:"header-content",style:({
      'background-image':
        _vm.setHeaderContentBackgroundImg == 'none'
          ? 'none'
          : 'url(' + _vm.setHeaderContentBackgroundImg + ')',
      width: _vm.contentWidth + 'px',
    })},[_c('div',{ref:"headerLogo",staticClass:"header-logo",style:(_vm.styleApi + ':' + _vm.setLogoMargin + 'px;height:' + _vm.height + 'px')},[_c('img',{attrs:{"src":require("./images/TopBannerLogo.png"),"alt":""}}),(_vm.versionNameShow == 1)?_c('a',{staticClass:"version-name",style:('vertical-align: ' +
          _vm.versionNameVerticalAlign +
          'px;color:' +
          _vm.versionNameColor +
          ';font-size:' +
          _vm.versionNameFontSize +
          'px;'),attrs:{"href":""}},[_vm._v(_vm._s(this.versionName))]):_vm._e()]),_vm._t("topBannerContent")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }