const indexMutations = {
    updateUid(state, newUid) {
        state.uid = newUid; // 更新 uid
    },
    updateClickUid(state, newUid) {
        state.clickuid = newUid; // 更新 uid
    },
    updateLeftNav(state, newList) {
        state.leftNav = newList; // 更新 uid
    },
    updateQuaryParams(state, newparams) {
        state.quaryParams = newparams; // 更新 uid
    },
    updateUserName(state, newuserName) {
        state.userName = newuserName; // 更新 uid
    },
    updateUserToken(state, newuserToken) {
        state.userToken = newuserToken; // 更新 uid
    },
    updateSpecialSubjectNavUid(state, specialSubjectNavUidData) {
        state.specialSubjectNavUid = specialSubjectNavUidData;
    },
    updateHomeNavList(state, newhomeNavList) {
        state.homeNavList = newhomeNavList; // 更新 uid
    },
    
}

export default indexMutations